import { useState } from 'react'
import { generateHashString } from '#app/utils/generateHash'

interface ImageComponentProps
	extends React.ImgHTMLAttributes<HTMLImageElement> {
	src: string
	size?: 'original' | 'thumbnail'
}

const ImageComponent = ({
	src,
	size = 'original',
	alt,
	...rest
}: ImageComponentProps) => {
	const [imageSrc, setImageSrc] = useState<string | null>()
	const featureEnabled = ENV.IMAGE_FEATURE === 'true'
	if (!featureEnabled) {
		return <img src={src} alt={alt} {...rest} />
	}

	const handleImageError = () => {
		setImageSrc(`/resources/image?src=${src}&size=${size}`)
	}
	const hashed = generateHashString(src)

	return (
		<img
			src={
				imageSrc ||
				`https://storage.googleapis.com/plenty-prod/images/${size}/${hashed}.webp`
			}
			onError={handleImageError}
			alt={alt}
			{...rest}
		/>
	)
}

export default ImageComponent
