// function to generate a hash string from string
export function generateHashString(str: string): string {
	let hash = 0n // Use BigInt to handle large numbers
	if (str.length === 0) {
		return hash.toString()
	}
	for (let i = 0; i < str.length; i++) {
		const char = BigInt(str.charCodeAt(i))
		hash = (hash << 5n) - hash + char // Update hash using BigInt operations
	}
	hash = hash < 0n ? -hash : hash // Ensure the hash is always positive
	return hash.toString() // Return the result as a string
}
